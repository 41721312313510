<template>
	<div>
		<!--HIDDEN UPLOADFORM-->
		<form :id="`uploadform-${rnd}`" :ref="`uploadform-${rnd}`">
			<input
				id="fileUpload"
				:ref="`uploaddocument-${rnd}`"
				:accept="userOnboarding ? 'application/pdf' : '*/*'"
				type="file"
				class="inputfile hidden"
				name="media"
				@change="doUpload(false)"
			/>
		</form>

		<!--UPLOAD SCREEN-->
		<HokModal click-to-close :adaptive="true" :name="`uploadscreen-${rnd}`">
			<div class="p-10 pb-20">
				<div class="upload-wrapper">
					<label ref="upload-progress" :style="{ width: uploadProgressPercent + '%' }" />
				</div>
				<p class="text-center font-bold">{{ uploadProgressPercent }}%</p>
				<p v-if="uploadProgressPercent === 100" class="text-center mb-8">
					Deine Datei wird verarbeitet.
				</p>
				<Spinner v-if="uploadProgressPercent === 100" :fixed="false" />
			</div>
		</HokModal>

		<!--UPLOAD SCREEN MOBILE-->
		<HokModal click-to-close :adaptive="true" :name="`uploaddocumentmobile-${rnd}`">
			<div class="upload-section text-center">
				<h3 class="mt-4">Wie willst du die Datei hochladen?</h3>
				<HokButton fullwidth="mobile" color="main" class="mb-4" @click="startUpload">
					Am Smartphone
				</HokButton>
				<HokButton
					v-if="user && user.general && user.general.email"
					outline
					color="main"
					fullwidth="mobile"
					class="mb-4"
					@click="doUpload(true)"
				>
					via E-Mail
				</HokButton>
				<p class="text-xs text-center mt-1 mb-0">(Upload-Link via E-Mail erhalten)</p>
			</div>
		</HokModal>

		<!--UPLOAD SCREEN DONE-->
		<HokModal click-to-close :adaptive="true" :name="`uploaddone-${rnd}`">
			<div class="upload-section text-center">
				<template v-if="mailSent">
					<h3>E-Mail versendet</h3>
					<p class="mb-10">
						Überprüfe deinen Posteingang
						<b v-if="user && user.general && user.general.email" class="text-xs break-all">
							{{ user.general.email }}
						</b>
					</p>
				</template>
				<template v-else>
					<h3 class="mt-4">Dateiupload erfolgreich!</h3>
					<p class="mb-10">Diese Datei wird nun bei all deinen Bewerbungen angehängt.</p>
					<div v-if="uploadedFile && uploadedFile.url" class="file-preview mb-5 flex flex-col">
						<img
							:alt="uploadedFile.title"
							:src="previewUrl(uploadedFile.url)"
							class="shadow-md mx-auto h-full mb-2"
						/>
						<p v-if="uploadedFile.originalName" class="text-sm mt-4 mb-0">
							{{ uploadedFile.originalName }}
						</p>
					</div>
				</template>
				<HokButton fullwidth="mobile" data-cy="uploadDone" color="main" @click="uploadDone">
					Okay
				</HokButton>
			</div>
		</HokModal>
		<!--UPLOAD SCREEN Error-->
		<HokModal
			click-to-close
			:adaptive="true"
			:name="`uploaderror-${rnd}`"
			:width="$isMobile.any ? '95%' : '350px'"
		>
			<div class="upload-section">
				<h3>{{ uploadError.title }}</h3>
				<p v-html="uploadError.text"></p>
				<HokButton fullwidth="always" data-cy="uploadDone" color="main" @click="retryUpload">
					Erneut versuchen
				</HokButton>
			</div>
		</HokModal>
	</div>
</template>

<script lang="ts">
import { APIObjectType, APITypeObjectId, UserExtraType } from '@hokify/common';
import type { IAPILoginUser, IAPIUserExtra } from '@hokify/common';
import { delayedReject } from '@hokify/shared-components-nuxt3/lib/helpers/promise';
import { defineComponent, PropType } from 'vue';

function hasClick(obj: any): obj is HTMLElement {
	return typeof obj.click === 'function';
}

export default defineComponent({
	name: 'Upload',
	data() {
		const uploadedFile: IAPIUserExtra = {} as IAPIUserExtra;
		const uploadDocumentId = undefined as APITypeObjectId<APIObjectType.UserExtra> | undefined;
		const uploadDocumentTitle = '' as string | undefined;
		const uploadDocumentType = '' as UserExtraType | string | undefined;
		const documenttypes: {
			name: string;
			key: string;
			type: string;
			selectable?: boolean;
		}[] = [];
		const uploadProgressPercent = 0;
		const isDocument = false;
		const mailSent = false;
		const uploadError = { title: '', text: '' };

		return {
			rnd: Math.random(),
			documenttypes,
			isDocument,
			mailSent,
			uploadProgressPercent,
			uploadDocumentType,
			uploadDocumentTitle,
			uploadDocumentId,
			uploadedFile,
			cv: {
				type: 'cv',
				name: 'Lebenslauf'
			},
			uploadError
		};
	},
	computed: {
		user(): IAPILoginUser | undefined {
			return this.$nuxt?.$userProfileStore?.obj;
		},
		selectableDocumentTypes() {
			return this.documenttypes.filter(
				doc => (doc as any).selectable && (doc as any).type === 'document'
			);
		},
		selectableCertificateTypes() {
			return this.documenttypes.filter(
				doc => (doc as any).selectable && (doc as any).type === 'certificate'
			);
		}
	},
	mounted() {
		this.getUserDocumenttypes()
			?.then(types => {
				this.documenttypes = types;
			})
			.catch(err => this.$nuxt.$errorHandler(err));
	},
	methods: {
		uploadDone() {
			this.$modal.hide(`uploaddone-${this.rnd}`);
			this.mailSent = false;
			if (this.multipleUpload) {
				this.$page.goBack();
			}
		},
		startUpload(closeDialog = true) {
			if (this.requestVideoUpload) {
				this.initializeDB();
				return this.doUpload(false);
			}

			if (closeDialog) {
				this.$modal.hide(`uploaddocumentmobile-${this.rnd}`);
			}

			// do not send select events on upload page
			if (this.$route?.name !== 'pwa-webupload-docType') {
				if (this.uploadDocumentType === 'cv') {
					this.$trackUserEvent?.('user_settings_document_select', {
						answerType: 'file-cv'
					});
				} else if (this.isDocument) {
					this.$trackUserEvent?.('user_settings_document_select', {
						answerType: 'file-document'
					});
				}
			}

			if (hasClick(this.$refs[`uploaddocument-${this.rnd}`])) {
				(this.$refs[`uploaddocument-${this.rnd}`] as any).click();
			}
		},
		prepareUploadDocument(
			title: string,
			type: UserExtraType | string,
			forceUpload = false,
			id: APITypeObjectId<APIObjectType.UserExtra> | undefined = undefined
		) {
			this.uploadDocumentId = id;
			this.uploadDocumentType = type;
			this.uploadDocumentTitle = title;
			this.isDocument =
				this.uploadDocumentType.split(':')[0] === 'document' ||
				this.uploadDocumentType.split(':')[0] === 'certificate';

			if (!forceUpload && (this.$isMobile.phone || this.$isMobile.tablet)) {
				this.$modal.show(`uploaddocumentmobile-${this.rnd}`);
			} else {
				return this.startUpload(false);
			}
		},
		async doUpload(viaEmail = false) {
			this.$modal.hide(`uploaddocumentmobile-${this.rnd}`);
			this.$modal.hide('uploaddocument');

			if (!this.uploadDocumentType) {
				return;
			}

			if (!this.requestVideoUpload && !this.userOnboarding) {
				this.$modal.show(`uploadscreen-${this.rnd}`);
			} else if (this.userOnboarding) {
				this.$emit('uploading');
			}

			const form = this.$refs[`uploadform-${this.rnd}`];
			let formData;

			if (viaEmail) {
				// build formdData
				formData = new FormData();
				formData.append('url', '[NO_FILE_YET]');
				formData.append('type', this.uploadDocumentType);
				formData.append('title', this.uploadDocumentTitle);
				formData.append('visible', false);

				// tracking
				if (this.uploadDocumentType === 'cv') {
					this.$trackUserEvent?.('user_settings_cv_select', {
						answerType: 'mail-cv-upload'
					});
				} else if (this.isDocument) {
					this.$trackUserEvent?.('user_settings_document_select', {
						answerType: 'mail-doc-upload'
					});
				}
			} else if (this.requestVideoUpload) {
				formData = new FormData();
				formData.append('url', '[NO_FILE_YET]');
				formData.append('type', this.uploadDocumentType);
				formData.append('title', this.uploadDocumentTitle);
				formData.append('visible', true);
				formData.append('requestVideoUpload', 'zip');
			} else {
				formData = new FormData(form as HTMLFormElement);
				// do not send undefined id
				if (this.uploadDocumentId) {
					formData.append('_id', this.uploadDocumentId);
				}
				formData.append('returnInfo', true);
				formData.append('type', this.uploadDocumentType);
				formData.append('title', this.uploadDocumentTitle);
				formData.append('visible', true);
				if (this.uploadDocumentType === 'cv') {
					formData.append('parseCV', this.userOnboarding);
				}
				formData.append('replaceRejectedDocument', this.replaceRejectedDocument);
			}

			try {
				const profileExtra = await this.uploadExtra({
					postData: formData,
					onUploadProgress: evt => {
						if (evt.total) {
							this.uploadProgressPercent = Math.round((evt.loaded / evt.total) * 100);
						}
					}
				});

				let file = profileExtra.extra;

				// upload video
				if (profileExtra.directUpload) {
					let uploadHasStarted = false;
					try {
						// if SW or BG-sync not available, throw error
						if (!('serviceWorker' in navigator)) {
							throw new Error('serviceWorker not supported by this browser');
						}

						await this.saveFileToDB(profileExtra.directUpload.uploadUrl, this.video.data);

						// resolve when upload from SW is done
						// eslint-disable-next-line no-async-promise-executor
						await new Promise(async (resolve, reject) => {
							try {
								const channel = new BroadcastChannel('fileUpload');
								await channel.addEventListener('message', event => {
									if (
										event.data.title === 'uploadStarted' ||
										event.data.title === 'uploadFinished'
									) {
										if (event.data.title === 'uploadFinished') {
											resolve(event.data.title);
										} else if (event.data.title === 'uploadStarted') {
											uploadHasStarted = true;
										}
									} else {
										reject(event.data.title);
									}
								});

								// wait till activation phase has ended too
								navigator.serviceWorker.ready.then(swRegistration => {
									// send message 'uploadFile' to SW, to start upload
									swRegistration.active?.postMessage('uploadFile');
								});

								// wait 3 seconds for service worker to start uploading
								// otherwise upload video directly
								await Promise.race([
									new Promise(resolveInner => {
										if (uploadHasStarted) {
											resolveInner('uploadHasStarted');
										}
									}),
									delayedReject(3000, 'uploadFile did not start within 3 seconds')
								]);
							} catch (e) {
								reject(e);
							}
						});
					} catch (e) {
						// serviceworker/sync not supported
						console.error('background upload failed, using fallback upload instead', e);
						await this.uploadVideo({
							url: profileExtra.directUpload.uploadUrl,
							file: this.video.data
						});
					}

					// after uploading the file to S3, submit URL + additional data
					formData = new FormData();
					formData.append('url', profileExtra.directUpload.file);
					formData.append('hash', profileExtra.directUpload.hash);
					formData.append('videoWidth', this.videoWidth);
					formData.append('videoHeight', this.videoHeight);
					formData.append('type', this.uploadDocumentType);
					formData.append('title', this.uploadDocumentTitle);
					formData.append('visible', true);

					file = await this.uploadExtra({ postData: formData });
				}

				// tracking only block
				// send mail events
				if (this.$route?.name === 'pwa-webupload-docType') {
					if (this.uploadDocumentType === 'pic') {
						this.$trackUserEvent?.('user_settings_pic_finish', {
							answerType: 'mail-pic-upload'
						});
					} else if (this.uploadDocumentType === 'cv') {
						this.$trackUserEvent?.('user_settings_cv_finish', {
							answerType: 'mail-cv-upload'
						});
					} else if (this.isDocument) {
						this.$trackUserEvent?.('user_settings_document_finish', {
							answerType: 'mail-doc-upload'
						});
					}
				}
				// send non mail events
				else if (!viaEmail) {
					if (this.uploadDocumentType === 'cv') {
						this.$trackUserEvent?.('user_settings_cv_finish', {
							answerType: 'file-cv'
						});
					} else if (this.isDocument) {
						this.$trackUserEvent?.('user_settings_document_finish', {
							answerType: 'file-document'
						});
					}
				}

				if (!viaEmail) {
					this.mailSent = false;
					this.uploadedFile = file;
				} else {
					const mailData = {
						typeid: file._id,
						description: file.title,
						type: 'extra'
					};

					await this.$nuxt?.$userProfileExtraStore?.sendFileuploadMail(mailData);

					this.mailSent = true;
				}

				this.uploadDocumentType = undefined;
				this.uploadDocumentTitle = undefined;
				this.isDocument = false;

				if (!this.requestVideoUpload && !this.userOnboarding) {
					this.$modal.hide(`uploadscreen-${this.rnd}`);
					this.$modal.show(`uploaddone-${this.rnd}`);
					this.$emit('done', this.uploadedFile);
				} else if (this.userOnboarding) {
					this.$emit('upload-done');
				}
			} catch (err: any) {
				this.$modal.hide(`uploadscreen-${this.rnd}`);
				this.$modal.hide(`uploaddone-${this.rnd}`);
				if (err.response?.data?.code === 'FILE_TOO_LARGE') {
					const BYTES_TO_MB = 1000 * 1000;
					(this.$refs[`uploaddocument-${this.rnd}`] as any).value = '';
					this.uploadError = {
						title: 'Datei zu groß!',
						text: `Erlaubte Dateigröße: ${(err.response.data.maxBytes / BYTES_TO_MB).toFixed(0)} MB`
					};
					this.$modal.show(`uploaderror-${this.rnd}`);
				} else if (err.response?.data?.code === 'FILE_ALREADY_EXISTS') {
					// Clearing input to make sure chrome fires onchange event when one tries to upload the same file
					(this.$refs[`uploaddocument-${this.rnd}`] as any).value = '';
					this.uploadError = {
						title: 'Datei bereits vorhanden!',
						text: `Bitte überprüfe, ob du die richtige Datei ausgewählt hast.`
					};
					this.$modal.show(`uploaderror-${this.rnd}`);
				} else {
					console.error('error is:', err);
					this.$modal.show(`uploaderror-${this.rnd}`);
					(this.$refs[`uploaddocument-${this.rnd}`] as any).value = '';
					this.uploadError = {
						title: 'Upload fehlgeschlagen!',
						text: 'Erlaubte Dateiformate:<br><br><strong>pdf, doc, png, jpg, jpeg</strong>'
					};
				}
			}
		},
		saveFileToDB(url, file) {
			return new Promise<void>((resolve, reject) => {
				const tmpObj = { url, file };

				const myDB = window.indexedDB.open('tempFileDB');

				myDB.onsuccess = function () {
					const objStore = this.result
						.transaction('fileObjStore', 'readwrite')
						.objectStore('fileObjStore');
					objStore.clear(); // reset store, before adding new file
					objStore.add(tmpObj);
					resolve();
				};

				myDB.onerror = err => {
					reject(err);
				};
			});
		},
		initializeDB() {
			const fileDB = window.indexedDB.open('tempFileDB');

			fileDB.onupgradeneeded = event => {
				const db = (event.target as any).result;

				const fileObjStore = db.createObjectStore('fileObjStore', { autoIncrement: true });
				fileObjStore.createIndex('url', 'url');
				fileObjStore.createIndex('file', 'file');
			};
		},
		previewUrl(url) {
			const {
				public: { API_HOST_BROWSER }
			} = useRuntimeConfig();
			return `${
				API_HOST_BROWSER || 'https://test.hokify.com'
			}/documentpreview/${encodeURIComponent(url)}.png?height=300`;
		},
		retryUpload() {
			this.$modal.hide(`uploaderror-${this.rnd}`);
			this.$emit('retry-upload', {
				title: this.uploadDocumentTitle,
				type: this.uploadDocumentType
			});
		},
		uploadExtra(payload) {
			return this.$nuxt?.$userProfileExtraStore?.uploadExtra(payload);
		},
		uploadVideo(payload) {
			return this.$nuxt?.$userProfileExtraStore?.uploadVideo(payload);
		},
		getUserDocumenttypes() {
			return this.$nuxt?.$valuesStore?.getUserDocumenttypes();
		}
	},
	props: {
		multipleUpload: { type: Boolean, default: false },
		requestVideoUpload: { type: Boolean, default: false },
		videoWidth: { type: Number, default: 300 },
		videoHeight: { type: Number, default: 300 },
		video: { default: undefined, type: Object as PropType<any> },
		userOnboarding: { type: Boolean, default: false },
		replaceRejectedDocument: { type: Boolean, default: false }
	},
	emits: ['uploading', 'done', 'retry-upload', 'upload-done']
});
</script>

<style scoped lang="scss">
.upload-wrapper {
	text-align: left;
	border: 1px solid #0fb1af;
	border-radius: 20px;
	width: 100%;
	overflow: hidden;
	height: 8px;
	margin-bottom: 10px;

	label {
		height: 20px;
		background-color: #0fb1af;
		display: inline-block;
	}
}

.file-preview {
	img,
	iframe {
		height: 210px;
		// > iPhone 6
		@media (min-width: 375px) {
			height: 320px;
		}
	}
}
</style>
